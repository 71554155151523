@import url('./animations.css');

:root {
  box-sizing: border-box;
}

svg {
  width: auto;
  /* height: 1em; */
}
svg path {
  fill: currentColor;
}

.MuiFormControlLabel-asterisk {
  line-height: 1;
  margin-top: 9px;
}

input::-webkit-datetime-edit-fields-wrapper {
  color: grey;
}

button.MuiButtonBase-root {
  text-transform: none;
}
