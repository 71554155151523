.spinner.light {
  --color: rgba(255, 255, 255, 0.8);
}
.spinner.dark {
  --color: rgba(0, 0, 0, 0.4);
}

.spinner:after {
  content: ' ';
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 0.14em solid var(--color);
  border-color: var(--color) transparent var(--color) transparent;
  animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
